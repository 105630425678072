import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "@assets/styles/main.scss";
import AppLayout from "@components/layouts/AppLayout";
import RouterConstants from "@constants/RouterConstants.ts";
import { ThemeProvider } from "@mui/material/styles";
import Home from "@pages/Home/Home.tsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import appTheme from '@constants/LayoutTheme';
import "./App.scss";
import Widgets from '@pages/Widgets/Widgets';
import Videos from '@pages/Videos/Videos';
import Integrations from '@pages/Integrations/Integrations';
import Settings from '@pages/Settings/Settings';

function App() {
	return (
		<ThemeProvider theme={appTheme}>
			<BrowserRouter>
				<Routes>
					<Route element={<AppLayout />}>
						<Route path={RouterConstants.HOME.path} element={<Home />} />
						<Route path={RouterConstants.WIDGETS.path} element={<Widgets />} />
						<Route path={RouterConstants.VIDEOS.path} element={<Videos />} />
						<Route path={RouterConstants.INTEGRATIONS.path} element={<Integrations />} />
						<Route path={RouterConstants.SETTINGS.path} element={<Settings />} />
					</Route>
				</Routes>
			</BrowserRouter>
			<ToastContainer
				className="toast"
				position="top-center"
				autoClose={3000}
				hideProgressBar
				closeOnClick
				pauseOnHover={false}
				draggable={false}
				icon={false}
			/>
		</ThemeProvider>
	);
}

export default App;
