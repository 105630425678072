import { makeAutoObservable } from "mobx";
import AuthStore, { IAuthStore } from "@pages/auth/store.tsx";
import AppStore, { IAppStore } from "@pages/store.tsx";
import UIStore, { IUIStore } from "../ui-store.tsx";

export interface IRootStore {
	appStore: IAppStore;
	authStore: IAuthStore;
	uiStore: IUIStore;
}

export default class RootStore implements IRootStore {
	appStore = new AppStore() as IAppStore;
	authStore = new AuthStore() as IAuthStore;
	uiStore = new UIStore(this) as IUIStore;

	constructor() {
		makeAutoObservable(this);
	}
}
