import { createTheme, ThemeOptions } from "@mui/material";

const theme: ThemeOptions = {
	palette: {
		primary: {
			main: "#1f35c4",
			dark : "#03034a",
			light: "#2b32ff",
			contrastText: "#fff"
		}
	},
	typography: {
		fontFamily: "Lato, sans-serif",
	},
};

const appTheme = createTheme(theme, {
	palette: {
		gray: {
			color: {
				main: "#888888",
			},
			name: "gray",
		},
		gray100: {
			color: {
				main: "#fafafa",
			},
			name: "gray-100",
		},
		gray200: {
			color: {
				main: "#e5e4e4",
			},
			name: "gray-200",
		},
	},
});

export default appTheme;
