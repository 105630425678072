import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./Widgets.module.scss";

const Widgets = () => {
	const { t } = useTranslation("translation", { keyPrefix: "home" });

	return (
		<Box className={styles.container}>
			<Typography variant="h6">
				{t("welcomeMessage")}
			</Typography>
		</Box>
	);
};

export default observer(Widgets);
