import {IRootStore} from "@stores/root-store.tsx";
import {makeAutoObservable} from "mobx";

export interface IUIStore {
    rootStore: IRootStore;
}
export default class UIStore implements IUIStore {
    rootStore: IRootStore;

    constructor(rootStore: IRootStore) {
		makeAutoObservable(this);
		this.rootStore = rootStore;
	}
}