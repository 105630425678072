export default class RouterConstants {
	public static readonly HOME = {
		path: "/",
	};
	public static readonly WIDGETS = {
		path: "/widgets",
	};
	public static readonly VIDEOS = {
		path: "/videos",
	};
	public static readonly INTEGRATIONS = {
		path: "/integrations",
	};
	public static readonly SETTINGS = {
		path: "/settings",
	};
}
