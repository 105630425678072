import PageLayout from "@components/layouts/PageLayout";
import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./Home.module.scss";
import { styled } from "@mui/material/styles";

const HomeTitle = styled(Typography)({
	fontWeight: 600
})

const HomeSubTitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.grey[700],
	marginTop: 10
}));

const Home = () => {
	const { t } = useTranslation("translation", { keyPrefix: "home" });

	return (
		<PageLayout>
			<Box className={styles.BoxContainer}>
				<HomeTitle
					variant="h4"
					className={styles.homeTitle}
					dangerouslySetInnerHTML={{ __html: t("welcomeMessage") }}
				></HomeTitle>
				<HomeSubTitle variant="body1">{t("subTitle")}</HomeSubTitle>
			</Box>
		</PageLayout>
	);
};

export default observer(Home);
