import ReconessLogo from "@assets/images/ReconessLogo.png";
import { Environment } from "@constants/Environments";
import { MenuOpenRounded, MenuRounded, PersonOutline } from "@mui/icons-material";
import { AppBar, IconButton, Menu, MenuItem, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";

interface HeaderProps {
	toggleDrawer: () => void;
	open: boolean;
}

const Header: React.FC<HeaderProps> = ({ toggleDrawer, open }) => {
	const { t } = useTranslation("translation");
	const [profileMenu, setProfileMenu] = useState<null | HTMLElement>(null);
	const [environment, setEnvironment] = useState(Environment.production);

	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setProfileMenu(event.currentTarget);
	};

	const handleMenuClose = () => {
		setProfileMenu(null);
	};

	const handleLogout = () => {
		handleMenuClose();
	};

	const handleEnvironmentChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setEnvironment(event.target.value as Environment);
	};

	return (
		<AppBar position="sticky" className={styles.appBar}>
			<Toolbar>
				<Typography variant="h6" component="div" className={styles.headerTitle}>
					<img src={ReconessLogo} alt="Reconess Logo" style={{ marginRight: 15, width: 150 }} />
					{t("header.socialVideoAnalyzer")}
					<Toolbar className={styles.headerToolbar}>
						<IconButton onClick={toggleDrawer}>{open ? <MenuOpenRounded /> : <MenuRounded />}</IconButton>
					</Toolbar>
				</Typography>

				<div className={styles.profileMenu}>
					<IconButton
						aria-label="account of current user"
						aria-controls="menu-appbar"
						aria-haspopup="true"
						onClick={handleMenuOpen}
					>
						<PersonOutline />
					</IconButton>
					<Menu
						id="menu-appbar"
						anchorEl={profileMenu}
						anchorOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						keepMounted
						transformOrigin={{
							vertical: "top",
							horizontal: "right",
						}}
						open={Boolean(profileMenu)}
						onClose={handleMenuClose}
					>
						<MenuItem onClick={handleLogout}>{t("common.logout")}</MenuItem>
					</Menu>
				</div>

				<div>
					<select value={environment} onChange={handleEnvironmentChange}>
						{Object.values(Environment).map((env) => (
							<option key={env} value={env}>
								{t(`common.${env}`)}
							</option>
						))}
					</select>
				</div>
			</Toolbar>
		</AppBar>
	);
};

export default Header;
