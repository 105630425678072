import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import MainListItems from "./ListItems";
import styles from "./SideBar.module.scss";

const drawerWidth: number = 270;

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
	"& .MuiDrawer-paper": {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		borderRadius: 30,
		padding: '4px 10px',
		backgroundColor: theme.palette.primary.dark,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: "border-box",
		...(!open && {
			overflowX: "hidden",
			transition: theme.transitions.create("width", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up("sm")]: {
				width: theme.spacing(9),
			},
		}),
	},
}));

interface SideBarProps {
	open: boolean
}

const SideBar: React.FC<SideBarProps> = ({open}) => {

	return (
		<Box className={styles.sideBarContainer}>
			<Drawer variant="permanent" open={open}>
				<List component="nav">
					<MainListItems open={open}/>
				</List>
			</Drawer>
		</Box>
	);
}

export default SideBar;