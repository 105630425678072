import { HomeRounded, LayersRounded, SettingsRounded, VideoLibraryRounded, WidgetsRounded } from "@mui/icons-material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styles from "./SideBar.module.scss";

const itemList = [
	{ icon: <HomeRounded />, titleKey: "home", path: "/" },
	{ icon: <WidgetsRounded />, titleKey: "widgets", path: "/widgets" },
	{ icon: <VideoLibraryRounded />, titleKey: "videos", path: "/videos" },
	{ icon: <LayersRounded />, titleKey: "integrations", path: "/integrations" },
	{ icon: <SettingsRounded />, titleKey: "settings", path: "/settings" },
];

const CustomListItemIcon = styled(ListItemIcon)({
	color: "white",
	minWidth: 32,
});

interface MainListItemsProps {
	open: boolean;
}

const MainListItems: React.FC<MainListItemsProps> = ({ open }) => {
	const { t } = useTranslation("translation", { keyPrefix: "sidebar" });
	const { pathname } = useLocation();

	return (
		<>
			{itemList.map((item, index) => (
				<Link
					to={item.path}
					className={`${styles.listItemButton} ${pathname === item.path ? styles.active : ""}`}
					key={index}
				>
					<ListItemButton key={index}>
						<CustomListItemIcon>{item.icon}</CustomListItemIcon>
						{open && <ListItemText primary={t(item.titleKey)} />}
					</ListItemButton>
				</Link>
			))}
		</>
	);
};

export default MainListItems;
