import Header from "@components/layouts/Header";
import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import styles from "./AppLayout.module.scss";
import SideBar from "@components/layouts/SideBar";

const AppLayout: React.FC = () => {
  const [open, setOpen] = useState(true);
	const toggleDrawer = () => {
		setOpen(!open);
	};
	return (
		<>
			<Header open={open} toggleDrawer={toggleDrawer}/>
			<div className={styles.container}>
        <SideBar open={open}/>
				<Outlet />
			</div>
		</>
	);
};

export default AppLayout;
