import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import styles from './PageLayout.module.scss';


interface PageLayoutProps {
	children: ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ children }) => {
	return <Box className={styles.pageWrapper}>{children}</Box>;
};

export default PageLayout;
